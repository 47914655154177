<template>
  <section class="main-sec">
    <section class="">
      <section class="dtc-grid-2">
        <header class="ml-2 " style="padding-top:30px;margin-bottom:30px">
          <h3>在地養成-輔導問卷調查</h3>
        </header>
        <header>
          <h5 style="padding-top:30px;color: #333333f0;">
            <span class="mr-4"
              >年度:<strong>{{ year ? year : "暫無資料" }}</strong>
            </span>
            <span class="mr-4"
              >身分證號:<strong> {{ id ? id : "暫無資料" }}</strong></span
            >
            <span
              >姓名:<strong> {{ name ? name : "暫無資料" }}</strong></span
            >
          </h5>
        </header>
      </section>
      <div>
        <b-tabs content-class="mt-3" class="px-2">
          <b-tab title="個人基本資料" active
            ><SurveyPersonInfo
              :allData="allData"
              :key="'key0' + Object.keys(allData).length"
            ></SurveyPersonInfo
          ></b-tab>
          <b-tab title="就學中問卷" v-if="surveyNumber == 1"
            ><Ask901
              :allData="allData"
              :key="'key1' + Object.keys(allData).length"
            ></Ask901
          ></b-tab>
          <b-tab title="訓練中問卷" v-else-if="surveyNumber == 2"
            ><Ask902
              :allData="allData"
              :key="'key2' + Object.keys(allData).length"
            ></Ask902
          ></b-tab>
          <b-tab title="服務中問卷" v-else-if="surveyNumber == 3"
            ><AskInService
              :allData="allData"
              :key="'key3' + Object.keys(allData).length"
            ></AskInService
          ></b-tab>
          <b-tab title="已服務期滿仍繼續留任問卷" v-else-if="surveyNumber == 4"
            ><Ask1001
              :allData="allData"
              :key="'key4' + Object.keys(allData).length"
            ></Ask1001
          ></b-tab>
          <b-tab title="服務期滿已離任問卷" v-else-if="surveyNumber == 5"
            ><AskResign
              :allData="allData"
              :key="'key5' + Object.keys(allData).length"
            ></AskResign
          ></b-tab>
        </b-tabs>
      </div>
    </section>
  </section>
</template>

<script>
import queryString from "query-string";
import moment from "moment";
import SurveyPersonInfo from "./SurveyPersonInfo.vue";
import Ask901 from "./Ask901.vue";
import Ask902 from "./Ask902.vue";
import AskInService from "./AskInService.vue";
import Ask1001 from "./Ask1001.vue";
import AskResign from "./AskResign.vue";
import { store } from "@/store/global.js";
export default {
  data() {
    return {
      id: "",
      year: "",
      name: "",
      allData: {},

      surveyNumber: 0,
    };
  },
  components: {
    SurveyPersonInfo,
    Ask901,
    Ask902,
    AskInService,
    Ask1001,
    AskResign,
  },
  computed: {},
  methods: {
    async getData() {
      try {
        let qs = `?identifier=${this.id}&year=${this.year}`;
        let Items = await axios.get("LocalRecord/GetAnswer" + qs);
        this.allData = { ...Items };
        this.surveyNumber = Items.Question;
        this.name = Items.RecordBase.Name ? Items.RecordBase.Name : "";
      } catch (e) {
        this.$bvToast.toast("服務器錯誤", {
          title: e.response.data.Message,
          autoHideDelay: 5000,
          variant: "danger",
        });
      }
    },
  },
  async mounted() {
    this.id = `${window.sessionStorage.hfjjj}`.replace(/\"/g, "");
    this.year = new Date().getFullYear() - 1911;
    this.getData();
    this.$root.$on("get-newestsurvey-data", () => {
      this.getData();
    });
  },
};
</script>

<style lang="scss" scoped>
.main-sec {
  width: 100vw;
}
.dtc-grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
