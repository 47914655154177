<template>
  <section class="main-sec">
    <section class="start-sec mt-3">
      <main class="main-body-header">個人資料</main>
      <main class="main-body sec-2">
        <header>1. 姓名 :</header>
        <b-input v-model="name" placeholder="請輸入姓名" readonly></b-input>
      </main>
      <main class="main-body sec-2">
        <header>2. 生理性別 :</header>
        <b-form-radio v-model="gender" value="1" disabled>1 女</b-form-radio>
        <b-form-radio v-model="gender" value="2" disabled>2 男</b-form-radio>
        <b-form-radio v-model="gender" value="3" disabled>3 其他</b-form-radio>
      </main>
      <main class="main-body sec-2">
        <header>3. 身分別 :</header>
        <nav class="my-areas my-target">
          <b-form-checkbox class="ml-2" v-model="identity" value="1" disabled
            >1 離島，請註明離島名為</b-form-checkbox
          >
          <b-input
            v-model="identityText1"
            placeholder="請輸入島名"
            readonly
          ></b-input>
          <b-form-checkbox class="ml-2" v-model="identity" value="2" disabled
            >2 原住民，請註明族別</b-form-checkbox
          >
          <b-input
            v-model="identityText2"
            placeholder="請輸入族別"
            readonly
          ></b-input>
        </nav>
      </main>
      <main class="main-body sec-2">
        <header>4. 大學時期就讀的學校為 :</header>
        <b-input
          v-model="schoolName"
          placeholder="請輸入學校"
          readonly
        ></b-input>
      </main>
      <main class="main-body">
        <header>5. 就讀系所</header>
        <nav class="my-areas">
          <b-form-radio
            v-for="item in deps"
            :key="item"
            :value="item"
            v-model="selectedDep"
            disabled
          >
            {{ item }}
          </b-form-radio>
        </nav>
      </main>
      <main class="main-body">
        <header>6. 目前階段</header>
        <nav class="my-areas-stage">
          <b-form-radio
            v-for="item in stages"
            :key="item"
            :value="item"
            v-model="selectedStage"
            disabled
          >
            {{ item }}
          </b-form-radio>
        </nav>
      </main>
      <main class="main-body sec-2">
        <header>7. 大學畢業年份(例:民國108年，仍在學者不必填寫) :</header>
        <b-input
          v-model="graduateYear"
          placeholder="請輸入年份"
          readonly
        ></b-input>
      </main>
      <main class="main-body sec-2">
        <header>8. 專科時受訓之醫院(在學者不必填寫) :</header>
        <b-input v-model="hospital" placeholder="請輸入醫院" readonly></b-input>
      </main>
      <main class="main-body">
        <header>9. 專業科別(非醫學系者不必填寫) :</header>
        <nav class="my-areas-division">
          <b-form-radio
            v-for="item in divisions"
            :key="item"
            :value="item"
            v-model="selectedDivision"
            disabled
          >
            {{ item }}
          </b-form-radio>
        </nav>
      </main>
      <main class="main-body sec-2">
        <header>10.下鄉服務時的年份例民國108年，尚未服務者不必填寫) :</header>
        <b-input
          v-model="countryYear"
          placeholder="請輸入年份"
          readonly
        ></b-input>
      </main>
      <main class="main-body sec-2">
        <header>11.服務地點/目前任職地點(尚未服務者不必填寫) :</header>
        <b-input
          v-model="servicePlace"
          placeholder="請輸入地點"
          readonly
        ></b-input>
      </main>

      <main class="main-body sec-3">
        <header style="grid-column: 1/-1;">
          12.
          聯絡方式(如果你同意本計畫人員可以在未來就相關問題繼續請教你，請留下方便聯絡你的方式)
        </header>
        <div style="text-align:right">電話 :</div>
        <b-input v-model="contactTelPhone" placeholder="請輸入電話"></b-input>
        <div style="text-align:right">手機 :</div>
        <b-input v-model="contactCellPhone" placeholder="請輸入手機"></b-input>
        <div style="text-align:right">E-mail :</div>
        <b-input v-model="contactMail" placeholder="請輸入E-mail"></b-input>
        <div style="text-align:right">較可聯絡時間 :</div>
        <b-input v-model="contactTime" placeholder="請輸入時間"></b-input>
        <div style="text-align:right">Facebook帳號 :</div>
        <b-input v-model="contactFB" placeholder="請輸入Facebook"></b-input>
        <div style="text-align:right">LINE ID :</div>
        <b-input v-model="contactLine" placeholder="請輸入LINE ID"></b-input>
      </main>

      <b-button
        size="sm"
        variant="primary"
        class="mr-2"
        style="display:inline-block;width:80px;height:40px;margin-top:20px;margin-left:750px"
        @click="submitAnswer"
      >
        <i class="fas fa-save mr-1"></i>儲存
      </b-button>
      <div style="height:80px;"></div>
    </section>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
import moment from "moment";
const identityOpts = [1, 2];
const deps = [
  "1 醫學系",
  "2 護理系",
  "3 牙醫系",
  "4 物理治療學系",
  "5 營養學系",
  "6 職能治療學系",
  "7 藥學系",
  "8 醫技及醫檢學系",
  "9 呼吸治療學系",
  "10 生物醫學影像暨放射科學系",
  "11 臨床心理學系",
  "12 語言及聽力學系",
];
const stages = [
  "1 大一",
  "2 大二",
  "3 大三",
  "4 大四",
  "5 大五",
  "6 大六",
  "7 大七",
  "8 專科訓練中",
  "9 服務中",
  "10 服務期滿繼續留任",
  "11 已離任",
];
const divisions = [
  "內科",
  "外科",
  "婦產科",
  "小兒科",
  "家醫科",
  "急診科",
  "尚未選科",
];

const targets = [
  "單位業務及環境認識",
  "家訪(居家護理、TB關懷、長照評估..等)",
  "跟診(門診)",
  "社區健康服務: 衛教宣傳、社區健檢、診篩等",
];

export default {
  data() {
    return {
      avalible: false,

      name: "",
      gender: 0,
      identity: "",
      identityOpts,
      identityText1: "",
      identityText2: "",
      schoolName: "",
      deps,
      selectedDep: [],
      stages,
      selectedStage: [],
      graduateYear: "",
      hospital: "",
      divisions,
      selectedDivision: "",
      countryYear: "",
      servicePlace: "",
      contactTelPhone: "",
      contactCellPhone: "",
      contactMail: "",
      contactTime: "",
      contactFB: "",
      contactLine: "",
      id: "",
      year: "",
    };
  },
  props: ["allData"],
  computed: {
    personData() {
      let obj = `${this.name}${this.gender}${this.identity}${this.identityText1}${this.identityText2}${this.selectedDep}${this.selectedStage}${this.graduateYear}${this.hospital}${this.selectedDivision}${this.countryYear}${this.servicePlace}${this.contactTelPhone}${this.contactCellPhone}${this.contactMail}${this.contactTime}${this.contactFB}${this.contactLine}`;
      return obj;
    },
  },
  methods: {
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    checkIdentity(string) {
      const re = /^[A-Z][12]\d{8}$/;
      if (re.test(string)) {
        return;
      } else {
        this.$bvToast.toast(`無法儲存`, {
          title: "身分證格式錯誤!",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return "errors";
      }
    },
    async submitAnswer() {
      let ret = this.checkIdentity(this.id);
      if (ret == "errors") {
        return;
      }
      let RecordBase = {};
      RecordBase.Identifier = this.id;
      RecordBase.Year = this.year;
      RecordBase.Name = this.name;
      RecordBase.Gender = this.gender;
      RecordBase.Question_03 = this.getFinalAnswer(
        this.identity,
        this.identityOpts
      );
      RecordBase.Question_03_Island = this.identityText1;
      RecordBase.Question_03_Native = this.identityText2;
      RecordBase.Question_04 = this.schoolName;
      // RecordBase.Question_05 = this.getFinalAnswer(this.selectedDep, this.deps);
      RecordBase.Question_05 = this.selectedDep
        ? this.selectedDep.split(" ")[1]
        : "";
      // RecordBase.Question_06 = this.getFinalAnswer(
      //   this.selectedStage,
      //   this.stages
      // );
      RecordBase.Question_06 = this.selectedStage
        ? this.selectedStage.split(" ")[1]
        : "";

      RecordBase.Question_07 = this.graduateYear;
      RecordBase.Question_08 = this.hospital;
      // RecordBase.Question_09 = this.getFinalAnswer(
      //   this.selectedDivision,
      //   this.divisions
      // );
      RecordBase.Question_09 = this.selectedDivision
        ? this.selectedDivision
        : "";
      RecordBase.Question_10 = this.countryYear;
      RecordBase.Question_11 = this.servicePlace;
      RecordBase.Telphone = this.contactTelPhone;
      RecordBase.Mobile = this.contactCellPhone;
      RecordBase.Email = this.contactMail;
      RecordBase.ContactTime = this.contactTime;
      RecordBase.Facebook_Id = this.contactFB;
      RecordBase.Line_Id = this.contactLine;

      let obj = {};
      obj.RecordBase = RecordBase;

      try {
        await window.axios.post("LocalRecord/SetAnswer", obj);
        this.$root.$emit("get-newestsurvey-data");
        this.$bvToast.toast(`儲存成功`, {
          title: "個人資料",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    setPersonInfo() {
      let RecordBase = {};
      RecordBase.Identifier = this.id;
      RecordBase.Year = this.year;
      RecordBase.Name = this.name;
      RecordBase.Gender = this.gender;
      RecordBase.Question_03 = this.getFinalAnswer(
        this.identity,
        this.identityOpts
      );
      RecordBase.Question_03_Island = this.identityText1;
      RecordBase.Question_03_Native = this.identityText2;
      RecordBase.Question_04 = this.schoolName;
      RecordBase.Question_05 = this.getFinalAnswer(this.selectedDep, this.deps);
      RecordBase.Question_06 = this.getFinalAnswer(
        this.selectedStage,
        this.stages
      );
      RecordBase.Question_07 = this.graduateYear;
      RecordBase.Question_08 = this.hospital;
      RecordBase.Question_09 = this.getFinalAnswer(
        this.selectedDivision,
        this.divisions
      );
      RecordBase.Question_10 = this.countryYear;
      RecordBase.Question_11 = this.servicePlace;
      RecordBase.Telphone = this.contactTelPhone;
      RecordBase.Mobile = this.contactCellPhone;
      RecordBase.Email = this.contactMail;
      RecordBase.ContactTime = this.contactTime;
      RecordBase.Facebook_Id = this.contactFB;
      RecordBase.Line_Id = this.contactLine;

      let obj = {};
      obj.RecordBase = RecordBase;
      store.editItem = { ...obj };
    },
    fillForm() {
      let items = { ...this.allData.RecordBase };
      store.editItem = { ...this.allData.RecordBase };
      this.id = items.Identifier ? items.Identifier : "";
      this.year = items.Year ? items.Year : "";
      this.name = items.Name ? items.Name : "";
      this.gender =
        items.Gender == "F" || items.Gender == 1
          ? 1
          : items.Gender == "M" || items.Gender == 2
          ? 2
          : 3;
      this.identity = this.getOriginalAnswer(
        items.Question_03,
        this.identityOpts
      );
      this.identityText1 = items.Question_03_Island;
      this.identityText2 = items.Question_03_Native;
      this.schoolName = items.Question_04;
      // this.selectedDep = this.getOriginalAnswer(items.Question_05, this.deps);
      this.selectedDep = items.Question_05
        ? this.deps.find((s) => s.includes(items.Question_05))
        : "";
      // this.selectedStage = this.getOriginalAnswer(
      //   items.Question_06,
      //   this.stages
      // );
      this.selectedStage = items.Question_06
        ? this.stages.find((s) => s.includes(items.Question_06))
        : "";
      this.graduateYear = items.Question_07;
      this.hospital = items.Question_08;
      // this.selectedDivision = this.getOriginalAnswer(
      //   items.Question_09,
      //   this.divisions
      // )[0];
      this.selectedDivision = items.Question_09
        ? this.divisions.find((s) => s.includes(items.Question_09))
        : "";

      this.countryYear = items.Question_10;
      this.servicePlace = items.Question_11;
      this.contactTelPhone = items.Telphone;
      this.contactCellPhone = items.Mobile;
      this.contactMail = items.Email;
      this.contactTime = items.ContactTime;
      this.contactFB = items.Facebook_Id;
      this.contactLine = items.Line_Id;
    },
  },
  watch: {
    personData() {
      this.setPersonInfo();
    },
  },
  mounted() {
    this.fillForm();
  },
};
</script>

<style lang="scss" scoped>
.main-sec {
  margin: 0 auto;
}
.plan-header,
.main-body-header {
  background: #646b74;
  height: 42px;
  color: #fff;
  font-size: 20px;
  padding-left: 10px;
  line-height: 42px;
  text-align: center;
}

.plan-header {
  background: rgb(224, 224, 224);
  color: var(--dark);
  text-align: left;
}
.main-body-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.main-body {
  border: 1px solid #bababa;
  border-top: none;
  padding: 10px;
}
.start-sec {
  width: 900px;
  margin: 0 auto;
}
.my-areas {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 12px;
  // padding-left: 2rem;
  // margin-top: 1rem;
}
.my-areas-stage {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  gap: 12px;
}
.my-areas-division {
  display: grid;
  grid-template-columns: repeat(7, max-content);
  gap: 12px;
}

.my-target {
  grid-template-columns: repeat(2, max-content);
}
.sec-2 {
  display: grid;
  grid-template-columns: repeat(14, max-content);
  gap: 1rem;
}
.sec-3 {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 0.5rem;
}
.footer-btn-areas {
  > * {
    float: right;
  }
}
</style>
