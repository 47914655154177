<template>
  <section class="dtc-main-section input-container">
    <h4>
      尚在就學中的養成公費醫事人員請填寫該份表單(共15題)
    </h4>
    <header
      class="dtc-2-column header-dark"
      style="text-align:center;height:30px;"
    >
      <div>問題</div>
      <div>回答</div>
    </header>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>1</div>
        <div>
          請問您是如何得知有關養成公費醫事人員報考的資訊呢? (
          可複選，至多選擇三項 )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q1"
          :options="q1Opts"
          @change="updateItems('q1')"
        ></b-form-checkbox-group>
        <b-input v-model="q1Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>2</div>
        <div>
          <strong>需求評估 :</strong
          ><br />若回到高中時期，您會希望有以下的協助嗎?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q2"
          :options="q2Opts"
          @change="updateItems('q2')"
        ></b-form-checkbox-group>
        <b-input v-model="q2Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>3</div>
        <div>
          請問您是何時開始了解公費生的權利義務呢 ?
          (因各類醫事科系在校就讀時間皆不同，故僅須就實際就讀年份做填答)
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q3"
          :options="q3Opts"
        ></b-form-radio-group>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>4</div>
        <div>
          您是否曾因為公費身份被師長、學校職 員、同儕朋友另眼相待呢? (
          無論是羨慕或忌妒、正向或負向的對待 )
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q4"
          :options="q4Opts"
        ></b-form-radio-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>5</div>
        <div>您是否不希望同學、學長姐、老師們知道您的公費身份呢?</div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q5"
          :options="q4Opts"
        ></b-form-radio-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>6</div>
        <div>和其他同學比起來，課業對您來說是否較為困難呢?</div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q6"
          :options="q6Opts"
        ></b-form-radio-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>7</div>
        <div>
          若校內有養成公費生的相關組織，您認為它帶給您的是什麼樣的幫助呢? (
          請選擇最重要的三項 )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q7"
          :options="q7Opts"
          @change="updateItems('q7')"
        ></b-form-checkbox-group>
        <b-input v-model="q7Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>8</div>
        <div>
          遇到跟公費權利義務相關問題時，您會尋求誰的幫助呢? ( 請選擇最重要的三項
          )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q8"
          :options="q8Opts"
          @change="updateItems('q8')"
        ></b-form-checkbox-group>
        <b-input v-model="q8Other"></b-input>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>9</div>
        <div>
          您是否有過跟公部門反映公費相關問題 ( 例如 : 零用金金額、合約內容等等 )
          ，結果是 :
        </div>
      </div>
      <div>
        <b-form-radio-group
          stacked
          v-model="q9"
          :options="q9Opts"
        ></b-form-radio-group>
      </div>
    </main>

    <main class="dtc-2-column">
      <div class="number-div">
        <div>10</div>
        <div>
          如果曾考慮過放棄公費身份，請問有哪些原因呢?( 請選擇最重要的三項 )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q10"
          :options="q10Opts"
          @change="updateItems('q10')"
        ></b-form-checkbox-group>
        <b-input v-model="q10Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>11</div>
        <div>
          如果您之後的服務地點是「衛生所」，您對該執業環境的印象是什麼呢?(
          請選擇最重要的三項 )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q11"
          :options="q11Opts"
          @change="updateItems('q11')"
        ></b-form-checkbox-group>
        <b-input v-model="q11Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>12</div>
        <div>
          如果您之後的服務地點是「部立醫院」，您對該執業環境的印象是什麼呢?(
          請選擇最重要的三項 )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q12"
          :options="q12Opts"
          @change="updateItems('q12')"
        ></b-form-checkbox-group>
        <b-input v-model="q12Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>13</div>
        <div>
          <strong>本題僅需就讀醫學系者填寫，非醫學系者不必填寫: </strong
          >您認為未來在專科選擇上可能會受到哪些因素影響呢? ( 請選擇最重要的三項
          )
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q13"
          :options="q13Opts"
          @change="updateItems('q13')"
        ></b-form-checkbox-group>
        <b-input v-model="q13Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>14</div>
        <div>
          <strong>需求評估 : </strong
          ><br />在求學的過程中，您會希望有以下哪些協助呢?(請選擇最重要的三項)
        </div>
      </div>
      <div>
        <b-form-checkbox-group
          stacked
          v-model="q14"
          :options="q14Opts"
          @change="updateItems('q14')"
        ></b-form-checkbox-group>
        <b-input v-model="q14Other"></b-input>
      </div>
    </main>
    <main class="dtc-2-column">
      <div class="number-div">
        <div>15</div>
        <div>
          <span>請問您對現有的養成公費政策有沒有任何想法及意見想提出呢?</span>
        </div>
      </div>
      <div>
        <div>簡答:</div>
        <b-textarea
          placeholder="請輸入"
          no-resize
          style="height:150px;"
          v-model="q15Other"
          spellcheck="false"
          maxlength="400"
        ></b-textarea>
      </div>
    </main>
    <b-button
      size="sm"
      variant="success"
      class="mr-2"
      style="display:inline-block;width:100px;height:40px;margin-top:20px;margin-left:750px"
      @click="submitAnswer"
      v-show="!closeBtn"
    >
      <i class="fas fa-save mr-1"></i>儲存問卷
    </b-button>
  </section>
</template>

<script>
import queryString from "query-string";
import { store } from "@/store/global.js";
const q1Opts = [
  "1 高中老師提過相關資訊",
  "2 在高中校園內發現相關招生資訊(例如:海報)",
  "3 父母、親朋好友告知",
  "4 在網路上查到相關資訊",
  "5 在報章雜誌上發現相關資訊",
  "6 其他:",
];
const q2Opts = [
  "1 公部門到各地區辦理細節說明會",
  "2 公部門多增加該資訊的網路宣傳",
  "3 養成公費生學長姐的經驗分享",
  "4 不需要任何幫助",
  "5 其他:",
];
const q3Opts = [
  "1 大一時",
  "2 大二時",
  "3 大三時",
  "4 大四時",
  "5 大五時",
  "6 大六時",
  "7 大七時",
];
const q4Opts = ["1 每次都會", "2 大部分時會", "3 偶爾會", "4 幾乎不會"];
const q6Opts = ["1 常常", "2 有時候", "3 不常", "4 幾乎沒有"];
const q7Opts = [
  "1 情感的支持(例如:心情不好的安慰)",
  "2 提供訊息或建議",
  "3 物質的支持(例如:提供歷屆考古題)",
  "4 歸屬感",
  "5 我認為不需要有相關組織",
  "6 其他:",
];
const q8Opts = [
  "1 找公費生同學或是學長姐談談",
  "2 找學校老師或行政人員談談",
  "3 找父母或親友談談",
  "4 找公部門的相關窗口談談",
  "5 找朋友、社團同學談談",
  "6 沒有反映過",
];
const q9Opts = [
  "1 回應很快也很有幫助",
  "2 回應很慢但很有幫助",
  "3 回應很快但沒有幫助",
  "4 回應很慢又沒有幫助",
  "5 近乎不理不睬",
  "6 沒有反映過",
];

const q10Opts = [
  "1 沒有想過",
  "2 影響親密關係的發展",
  "3 影響未來子女教養規劃",
  "4 未來生活環境不太理想",
  "5 未來職涯規劃受限",
  "6 與自己想學專業不符",
  "7 合約內容與其他類公費生相比太苛刻",
  "8 其他:",
];
const q11Opts = [
  "1 自己的專科會無法發揮及精進",
  "2 公衛保健的宣導更加重要",
  "3 業務包山包海、很繁雜",
  "4 沒有其他專科同儕可以互動",
  "5 病人數偏少",
  "6 薪資福利等誘因較醫院來的低",
  "7 病人會對醫生或單位有不信任感",
  "8 完全不了解該執業環境",
  "9 其他:",
];
const q12Opts = [
  "1 需要輪班",
  "2 需要支援其他科別",
  "3 院內行政僵化，包括主管行格等",
  "4 病人數偏少",
  "5 沒有額外福利，例如服務年資加成計算、偏鄉加給等",
  "6 病人會對醫生或單位有不信任感",
  "7 完全不了解該執業環境",
  "8 其他:",
];
const q13Opts = [
  "1 未來返鄉服務地點的健康需求",
  "2 未來返鄉服務地點的設備水準",
  "3 見實習的直接體認",
  "4 公費生學長姊的建議",
  "5 父母或重要親友的建議",
  "6 有利未來服務期滿後的職涯發展",
  "7 跟隨自己的興趣",
  "8 其他:",
];
const q14Opts = [
  "1 校內創立養成公費生的相關社團",
  "2 有養成公費生的專責導師",
  "3 見實習的直接體認",
  "4 有養成公費生的資訊交流平台",
  "5 和養成公費生學長姐有實際互動",
  "6 下鄉見習的機會",
  "7 其他:",
];

export default {
  data() {
    return {
      q1: [],
      q1Opts,
      q1Other: "",
      q2: [],
      q2Opts,
      q2Other: "",
      q3: "",
      q3Opts,
      q4: "",
      q4Opts,
      q5: "",
      q6: "",
      q6Opts,
      q7: [],
      q7Opts,
      q7Other: "",
      q8: [],
      q8Opts,
      q8Other: "",
      q9: "",
      q9Opts,
      q10: [],
      q10Opts,
      q10Other: "",
      q11: [],
      q11Opts,
      q11Other: "",
      q12: [],
      q12Opts,
      q12Other: "",
      q13: [],
      q13Opts,
      q13Other: "",
      q14: [],
      q14Opts,
      q14Other: "",
      q15Other: "",
      id: "",
      year: "",
      closeBtn: false,
    };
  },
  props: ["allData"],
  methods: {
    updateItems(key) {
      requestAnimationFrame(() => {
        if (this[key].length < 4) return;
        this[key].splice(3);
      });
    },
    getFinalAnswer(answer, options) {
      let final = "";
      if (
        typeof answer == "string" ||
        typeof answer == "number" ||
        answer == null
      ) {
        final = options.map((s, i) => (answer == s ? "Y" : "N")).join(",");
      } else {
        final = options
          .map((s, i) => (answer.find((s2) => s2 == s) ? "Y" : "N"))
          .join(",");
      }
      return final;
    },
    async submitAnswer() {
      let RecordQ1 = {};
      RecordQ1.Question_01 = this.getFinalAnswer(this.q1, this.q1Opts);
      RecordQ1.Question_01_Other = this.q1Other;
      RecordQ1.Question_02 = this.getFinalAnswer(this.q2, this.q2Opts);
      RecordQ1.Question_02_Other = this.q2Other;
      RecordQ1.Question_03 = this.getFinalAnswer(this.q3, this.q3Opts);
      RecordQ1.Question_04 = this.getFinalAnswer(this.q4, this.q4Opts);
      RecordQ1.Question_05 = this.getFinalAnswer(this.q5, this.q4Opts);
      RecordQ1.Question_06 = this.getFinalAnswer(this.q6, this.q6Opts);
      RecordQ1.Question_07 = this.getFinalAnswer(this.q7, this.q7Opts);
      RecordQ1.Question_07_Other = this.q7Other;
      RecordQ1.Question_08 = this.getFinalAnswer(this.q8, this.q8Opts);
      RecordQ1.Question_08_Other = this.q8Other;
      RecordQ1.Question_09 = this.getFinalAnswer(this.q9, this.q9Opts);
      RecordQ1.Question_10 = this.getFinalAnswer(this.q10, this.q10Opts);
      RecordQ1.Question_10_Other = this.q10Other;
      RecordQ1.Question_11 = this.getFinalAnswer(this.q11, this.q11Opts);
      RecordQ1.Question_11_Other = this.q11Other;
      RecordQ1.Question_12 = this.getFinalAnswer(this.q12, this.q12Opts);
      RecordQ1.Question_12_Other = this.q12Other;
      RecordQ1.Question_13 = this.getFinalAnswer(this.q13, this.q13Opts);
      RecordQ1.Question_13_Other = this.q13Other;
      RecordQ1.Question_14 = this.getFinalAnswer(this.q14, this.q14Opts);
      RecordQ1.Question_14_Other = this.q14Other;
      RecordQ1.Question_15 = this.q15Other;

      let obj = {};
      obj.RecordQ1 = RecordQ1;
      // obj.RecordBase = this.allData.RecordBase;
      obj.RecordBase = store.editItem.RecordBase;
      try {
        await window.axios.post("LocalRecord/SetAnswer", obj);
        this.$root.$emit("get-newestsurvey-data");
        this.$bvToast.toast(`儲存成功`, {
          title: "問卷調查",
          autoHideDelay: 5000,
          variant: "success",
        });
      } catch (e) {
        alert(e);
      }
    },
    getOriginalAnswer(final, options) {
      let originalAnswer = [];
      originalAnswer =
        (final &&
          final
            .split(",")
            .map((s, i) => (s == "Y" ? options[i] : ""))
            .filter((s) => s)) ||
        [];

      return originalAnswer;
    },
    fillForm() {
      let items = { ...this.allData.RecordQ1 };
      this.q1 = this.getOriginalAnswer(items.Question_01, this.q1Opts);
      this.q1Other = items.Question_01_Other ? items.Question_01_Other : "";
      this.q2 = this.getOriginalAnswer(items.Question_02, this.q2Opts);
      this.q2Other = items.Question_02_Other ? items.Question_02_Other : "";
      this.q3 = this.getOriginalAnswer(items.Question_03, this.q3Opts)[0];
      this.q4 = this.getOriginalAnswer(items.Question_04, this.q4Opts)[0];
      this.q5 = this.getOriginalAnswer(items.Question_05, this.q4Opts)[0];
      this.q6 = this.getOriginalAnswer(items.Question_06, this.q6Opts)[0];
      this.q7 = this.getOriginalAnswer(items.Question_07, this.q7Opts);
      this.q7Other = items.Question_07_Other ? items.Question_07_Other : "";
      this.q8 = this.getOriginalAnswer(items.Question_08, this.q8Opts);
      this.q8Other = items.Question_08_Other ? items.Question_08_Other : "";
      this.q9 = this.getOriginalAnswer(items.Question_09, this.q9Opts)[0];
      this.q10 = this.getOriginalAnswer(items.Question_10, this.q10Opts);
      this.q10Other = items.Question_10_Other ? items.Question_10_Other : "";
      this.q11 = this.getOriginalAnswer(items.Question_11, this.q11Opts);
      this.q11Other = items.Question_11_Other ? items.Question_11_Other : "";
      this.q12 = this.getOriginalAnswer(items.Question_12, this.q12Opts);
      this.q12Other = items.Question_12_Other ? items.Question_12_Other : "";
      this.q13 = this.getOriginalAnswer(items.Question_13, this.q13Opts);
      this.q13Other = items.Question_13_Other ? items.Question_13_Other : "";
      this.q14 = this.getOriginalAnswer(items.Question_14, this.q14Opts);
      this.q14Other = items.Question_14_Other ? items.Question_14_Other : "";
      this.q15Other = items.Question_15 ? items.Question_15 : "";
    },
  },
  mounted() {
    this.fillForm();
    this.closeBtn = store.editPerson ? store.editPerson.closeBtn : false;
    if (this.closeBtn) {
      document.querySelectorAll(".input-container input").forEach((e) => {
        e.disabled = true;
      });
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dtc-main-section {
  max-width: 890px;
  margin: 30px auto;
  display: grid;
}
.dtc-2-column {
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid black;
  border-top: none;
  > div {
    padding: 10px;
  }
  > div:first-child {
    border-right: 1px solid black;
    font-size: 16px;
  }
}
.header-dark {
  border: 1px solid black;
  line-height: 7px;
}
.number-div {
  display: grid;
  grid-template-columns: 10px 1fr;
  gap: 1rem;
}
.bold-text-before {
  position: relative;
  margin-top: 30px;
  &::before {
    position: absolute;
    top: -30px;
    font-weight: 900px !important;
    content: "需求評估:";
  }
}
</style>
